.gunmetal{
    background-color: #292f36;
}

.med-turquoise{
    color: #4ecdc4;
}

.mint-cream{
    color: #f7fff7;
}

.bittersweet{
    color: #ff6b6b;
}

.yellow-crayola{
    color: #ffe66d;
}
