body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("./assets/img/Header.jpg");
  background-position: center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
.gunmetal{
  background-color: #292f36;
  height: 100%;
  min-height: 100%;
}

.med-turquoise{
  background-color: #4ecdc4;
}

.mint-cream{
  color: #f7fff7;
}

.bittersweet{
  color: #ff6b6b;
}

.yellow-crayola{
  color: #ffe66d;
}

.photo{
  max-height: auto;
  max-width: 80%;
}



.margin-top {
  margin-top: 50px;
}

.portfolio-box-caption-content {
  line-height: 2rem;
}
.portfolio-box-caption-content a:hover {
  color: #fff;
  text-decoration: none;
}
.portfolio-box-caption-content i.fab {
  font-size: 2rem;
}

.portfolio-box {
  position: relative;
  display: block;
  max-width: 650px;
  margin: 0 auto;
}
.portfolio-box .portfolio-box-caption {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
  color: #fff;
  background: rgba(141, 145, 141, 0.9);
  transition: all 0.2s;
}
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
}
.portfolio-box
  .portfolio-box-caption
  .portfolio-box-caption-content
  .project-category,
.portfolio-box
  .portfolio-box-caption
  .portfolio-box-caption-content
  .project-name {
  padding: 0 15px;
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
}
.portfolio-box
  .portfolio-box-caption
  .portfolio-box-caption-content
  .project-category {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.portfolio-box
  .portfolio-box-caption
  .portfolio-box-caption-content
  .project-name {
  font-size: 18px;
}
.portfolio-box:hover .portfolio-box-caption {
  opacity: 1;
}
.portfolio-box:focus {
  outline: none;
}
@media (min-width: 768px) {
  .portfolio-box
    .portfolio-box-caption
    .portfolio-box-caption-content
    .project-category {
    font-size: 16px;
  }
  .portfolio-box
    .portfolio-box-caption
    .portfolio-box-caption-content
    .project-name {
    font-size: 22px;
  }
  .nav-container{
    
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.gunmetal{
    background-color: #292f36;
}

.med-turquoise{
    color: #4ecdc4;
}

.mint-cream{
    color: #f7fff7;
}

.bittersweet{
    color: #ff6b6b;
}

.yellow-crayola{
    color: #ffe66d;
}

